<template>
	<div class="ordereight">
		<div>已填写订单信息</div>
		<div class="con">
			<div align="left" class="title">患者信息</div>
			<el-table style="width: 100%" :data="patients">
				<el-table-column label="患者编号" width="180" prop="patientNum">
					
				</el-table-column>
				<el-table-column label="出生日期" width="180" prop="patientBirthday">
				</el-table-column>
				<el-table-column label="性别" prop="patientGender">
				</el-table-column>
			</el-table>
			<div align="left" class="title">矫治需求</div>
			<div align="left" class="subTitle">
				<div v-if="jituo[0]!=''">
					<div>基托颜色</div>
					<ul>
						<li v-for="(item,index) in jituo" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="zhengji[0]!=''">
					<div>正畸保持器</div>
					<ul>
						<li v-for="(item,index) in zhengji" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="gongneng[0]!=''">
					<div>功能矫治器</div>
					<ul>
						<li v-for="(item,index) in gongneng" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="luoxuan[0]!=''">
					<div>螺旋扩大器</div>
					<ul>
						<li v-for="(item,index) in luoxuan" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="yadian[0]!=''">
					<div>美白/阻鼾器/夜磨牙垫</div>
					<ul>
						<li v-for="(item,index) in yadian" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="qita[0]!=''">
					<div>其它类别</div>
					<ul>
						<li v-for="(item,index) in qita" :key="index">{{item}}</li>
					</ul>
				</div>
				<div v-if="heban[0]!=''">
					<div>FACE正畸专业颌板</div>
					<ul>
						<li v-for="(item,index) in heban" :key="index">{{item}}</li>
					</ul>
				</div>
				<div>治疗需求</div>
				<div style="padding-left: 1.25rem;">{{planDoctor}}</div>
				<div>矫治需求图片</div>
				<div style="padding-left: 1.25rem;">
				{{planPdf}}
				<img :src="planPdf" width="100" height="100" />
				</div>
			</div>
			<div align="left" class="title">X光片</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="quanjings">
					<el-table-column label="全景片"  prop="file">
					</el-table-column>
					<el-table-column label="上传日期"  prop="date">
					</el-table-column>
				</el-table>
			</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="ceweis">
					<el-table-column label="侧位片"  prop="file">
					</el-table-column>
					<el-table-column label="上传日期日期"  prop="date">
					</el-table-column>
				</el-table>
			</div>
			<!-- 口扫 -->
			<div align="left" class="title">口扫</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="kousaos">
					<el-table-column label="上传日期"  prop="date">
					</el-table-column>
					<el-table-column label="矫治器类型"  prop="jzqlx">
					</el-table-column>
					<el-table-column label="上颌"  prop="sh">
						<template slot-scope="scope">
							<span v-if="scope.row.sh==null">无</span>
							<span v-if="scope.row.sh!=null">{{scope.row.sh}}</span>
						</template>
					</el-table-column>
					<el-table-column label="下颌"  prop="xh">
						<template slot-scope="scope">
							<span v-if="scope.row.xh==null">无</span>
							<span v-if="scope.row.xh!=null">{{scope.row.xh}}</span>
						</template>
					</el-table-column>
					<el-table-column label="咬颌"  prop="yh">
						<template slot-scope="scope">
							<span v-if="scope.row.yh==null">无</span>
							<span v-if="scope.row.yh!=null">{{scope.row.yh}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 口内 -->
			<div align="left" class="title">口内</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="kouneis">
					<el-table-column label="上传日期"  prop="date">
						<template slot-scope="scope">
							<span v-if="scope.row.date==null">无</span>
							<span v-if="scope.row.date!=null">{{scope.row.date}}</span>
						</template>
					</el-table-column>
					<el-table-column label="左侧"  prop="zc">
						<template slot-scope="scope">
							<span v-if="scope.row.zc==null">无</span>
							<span v-if="scope.row.zc!=null">{{scope.row.zc}}</span>
						</template>
					</el-table-column>
					<el-table-column label="右侧"  prop="yc">
						<template slot-scope="scope">
							<span v-if="scope.row.yc==null">无</span>
							<span v-if="scope.row.yc!=null">{{scope.row.yc}}</span>
						</template>
					</el-table-column>
					<el-table-column label="正面"  prop="zm">
						<template slot-scope="scope">
							<span v-if="scope.row.zm==null">无</span>
							<span v-if="scope.row.zm!=null">{{scope.row.zm}}</span>
						</template>
					</el-table-column>
					<el-table-column label="上颌"  prop="sh">
						<template slot-scope="scope">
							<span v-if="scope.row.sh==null">无</span>
							<span v-if="scope.row.sh!=null">{{scope.row.sh}}</span>
						</template>
					</el-table-column>
					<el-table-column label="下颌"  prop="xh">
						<template slot-scope="scope">
							<span v-if="scope.row.xh==null">无</span>
							<span v-if="scope.row.xh!=null">{{scope.row.xh}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 面向 -->
			<div align="left" class="title">面向</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="mianxiangs">
					<el-table-column label="上传日期"  prop="date">
						<template slot-scope="scope">
							<span v-if="scope.row.date==null">无</span>
							<span v-if="scope.row.date!=null">{{scope.row.date}}</span>
						</template>
					</el-table-column>
					<el-table-column label="正面无笑容"  prop="zw">
						<template slot-scope="scope">
							<span v-if="scope.row.zw==null">无</span>
							<span v-if="scope.row.zw!=null">{{scope.row.zw}}</span>
						</template>
					</el-table-column>
					<el-table-column label="斜45度无笑容"  prop="xw">
						<template slot-scope="scope">
							<span v-if="scope.row.xw==null">无</span>
							<span v-if="scope.row.xw!=null">{{scope.row.xw}}</span>
						</template>
					</el-table-column>
					<el-table-column label="侧面无笑容"  prop="cw">
						<template slot-scope="scope">
							<span v-if="scope.row.cw==null">无</span>
							<span v-if="scope.row.cw!=null">{{scope.row.cw}}</span>
						</template>
					</el-table-column>
					<el-table-column label="正面微笑"  prop="zy">
						<template slot-scope="scope">
							<span v-if="scope.row.zy==null">无</span>
							<span v-if="scope.row.zy!=null">{{scope.row.zy}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 收货地址 -->
			<div align="left" class="title">收货信息</div>
			<div style="width: 100%;">
				<el-table style="width: 100%" :data="adds">
					<el-table-column label="收货人姓名"  prop="userName">
						
					</el-table-column>
					<el-table-column label="手机"  prop="phone">
						
					</el-table-column>
					<el-table-column label="地址"  prop="cityInfo">
						
					</el-table-column>
				</el-table>
			</div>
			<div>未填写订单信息</div>
			<div v-if="wrongMessages.length!=0" align="left">
				<ul>
					<li style="color: red;" v-for="(item,index) in wrongMessages" :key="index">{{item}}</li>
				</ul>
			</div>
			<div v-if="wrongMessages.length==0">
				无
			</div>
			<!-- 提交按钮 -->
			<button :disabled="disabled" @click="nextStep" class="btnNext" style="width: 30%;" type="button">下一步</button>
		</div>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				ruleForm: {
					oid: "0"
				},
				patients:[],//患者信息
				jituo:[],//基托颜色
				zhengji:[],//正畸保持器
				gongneng:[],//功能
				luoxuan:[],//螺旋
				yadian:[],//牙垫
				qita:[],//其它
				heban:[],//颌板
				planDoctor:'',//治疗需求
				planPdf:'',//矫治需求图片
				quanjings:[],//全景
				ceweis:[],//侧位片
				kousaos:[],//口扫
				kouneis:[],//口内
				mianxiangs:[],//面向
				adds:[],//收货地址,
				wrongMessages:[],//未填写的订单信息
				disabled:true,
			}
		},
		methods: {
			//查看该订单的所有信息
			getAllOrderInfo() {
				this.$axios.get("client/order/confirmOrder", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					console.log(res.data.data);
					let orderInfo=res.data.data.orderInfo;
					let orderResource=res.data.data.orderInfo.resource;
					let orderAddress=res.data.data.orderInfo.address;
					console.log(res.data.data.checked);
					//按钮是否能用
					if(res.data.data.checked==false){
						this.disabled=true;
					}else{
						this.disabled=false;
					}
					
					//1.设置患者信息
					let patientInfoObj={
						patientNum:orderInfo.patientNum,
						patientBirthday:orderInfo.patientBirthday,
						patientGender:orderInfo.patientGender
					}
					this.patients.push(patientInfoObj);
					//2.设备编码
					//设置基托颜色
					this.jituo=orderInfo.plan.jituo;
					//正畸保持器
					this.zhengji=orderInfo.plan.zhengji
					//功能
					this.gongneng=orderInfo.plan.gongneng
					//螺旋
						this.luoxuan=orderInfo.plan.luoxuan
					//牙垫
					this.yadian=orderInfo.plan.yadian
					//其它
					this.qita=orderInfo.plan.qita
					//颌板
					this.heban=orderInfo.plan.heban
					//治疗需求
					this.planDoctor=orderInfo.plan.planDoctor
					//矫治需求图片
					this.planPdf=orderInfo.plan.planPdf
					//3.X光片
					let quanjingfile=orderResource.quanjing;
					if(quanjingfile!=null){
						let qobj={
							file:quanjingfile,
							date:orderResource.qjDate
						}
						this.quanjings.push(qobj)
					}
					//侧位
					let ceweifile=orderResource.cewei;
					if(ceweifile!=null){
						let cobj={
							file:ceweifile,
							date:orderResource.cwDate
						}
						this.ceweis.push(cobj)
					}
					//4.口扫
					let ksobj={
						date:orderResource.ksDate,
						jzqlx:orderResource.jzqlx,
						jzqlx:orderResource.jzqlx,
						sh:orderResource.ksShang,
						xh:orderResource.ksXia,
						yh:orderResource.ksYao
					}
					this.kousaos.push(ksobj);
					//5.口内
					let knobj={
						date:orderResource.knDate,
						sh:orderResource.knShang,
						xh:orderResource.knXia,
						yc:orderResource.knYou,
						zm:orderResource.knZheng,
						zc:orderResource.knZuo
					}
					this.kouneis.push(knobj);
					//6.面向
					let mxobj={
						date:orderResource.mxDate,
						zw:orderResource.mxZheng,
						xw:orderResource.mxXie,
						cw:orderResource.mxCe,
						zy:orderResource.mxXiao,
					}
					this.mianxiangs.push(mxobj);
					//7.收货地址
					if(orderAddress!=null&&orderAddress!=undefined&&orderAddress!=""){
						let addressObj={
							userName:orderAddress.userName,
							//userName:'aaa',
							phone:orderAddress.phone,
							//phone:111111,
							cityInfo:orderAddress.cityInfo+orderAddress.address
							//cityInfo:"2222"
						}
						this.adds.push(addressObj);
					}
					
					//未填写订单信息
					console.log(res.data.data.wrongMessages);
					this.wrongMessages=res.data.data.wrongMessages
				}).catch(err => {
					console.log(err);
				});
			},
			//下一步
			nextStep(){
				this.$router.push("/addorder/ordernine");
			}
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 7);
			//设置默认地址
			sessionStorage.setItem("location", "ordereight")
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			if (oid == null) {
				this.$alert("请填写患者信息", "提示");
				this.$router.push("/orderprocess/allorder");
			} else {
				this.ruleForm.oid = oid;
				this.getAllOrderInfo();
			}
		},
		created: () => {

		}
	}
</script>

<style scoped="scoped">
	.ordereight {
		background-image: linear-gradient(to bottom, white, #9BEDEB, #28D9D5);
		height: 90%;
	}

	.con {
		width: 80%;
		height: 70%;
		margin: 0px auto;
		overflow-y: auto;
	}
	.title{
		color:#909399 ;
		font-size: 1.25rem;
		font-weight: bolder;
		padding: 0.625rem 0rem;
		border-bottom: 1px solid #909399;
		margin-bottom: 0.3125rem;
	}
	.subTitle{
		color:#909399 ;
		font-size: 1rem;
		padding: 0.625rem 0rem;
		border-bottom: 1px solid #909399;
		padding-left: 1.25rem;
	}
	.btnNext{
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
		margin-bottom: 1.25rem;
	}
	
</style>
